// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  color: #fff;
  background-color: #1a1919;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

ol, ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.container {
  position: relative;
  width: 100%;
  padding: 0 60px;
  box-sizing: border-box;

  @media only screen and (max-width: 1400px) {
    max-width: calc(100% - 68px);
    padding: 0 30px;
  }

  @media only screen and (max-width: 768px) {
    max-width: calc(100% - 38px);
    padding: 0 18px;
  }

  @media only screen and (max-width: 414px) {
    max-width: 100%;
    padding: 0 18px;
    margin: 0 10px;
  }

  @media (max-width: 320px) {
    max-width: 100%;
    padding: 0 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/index.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,gCAAgC;EAChC,eAAe;EACf,gBAAgB;EAChB,SAAS;EACT,UAAU;EACV,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,sBAAsB;;EAEtB;IACE,4BAA4B;IAC5B,eAAe;EACjB;;EAEA;IACE,4BAA4B;IAC5B,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,eAAe;IACf,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,eAAe;EACjB;AACF","sourcesContent":["body {\n  color: #fff;\n  background-color: #1a1919;\n  font-family: 'Inter', sans-serif;\n  font-size: 16px;\n  font-weight: 400;\n  margin: 0;\n  padding: 0;\n  border: 0;\n  outline: 0;\n  box-sizing: border-box;\n  overflow-x: hidden;\n}\n\nh1, h2, h3, h4, h5, h6, p {\n  margin: 0;\n}\n\nol, ul {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n}\n\n.container {\n  position: relative;\n  width: 100%;\n  padding: 0 60px;\n  box-sizing: border-box;\n\n  @media only screen and (max-width: 1400px) {\n    max-width: calc(100% - 68px);\n    padding: 0 30px;\n  }\n\n  @media only screen and (max-width: 768px) {\n    max-width: calc(100% - 38px);\n    padding: 0 18px;\n  }\n\n  @media only screen and (max-width: 414px) {\n    max-width: 100%;\n    padding: 0 18px;\n    margin: 0 10px;\n  }\n\n  @media (max-width: 320px) {\n    max-width: 100%;\n    padding: 0 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
