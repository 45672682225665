// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollButton {
    position: fixed;
    width: 100px;
    right: 0;
    bottom: 0;
    height: 20px;
    font-size: 1rem;
    z-index: 1;
    cursor: pointer;
    text-decoration: none;
    background-image: linear-gradient(90deg, #59b720, #eeff00);
    margin: 10px;
    padding: 15px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: #fff;
    border-radius: 10px;
    font-weight: 500;
}

.scrollButton:hover {
    background-position: right center; /* change the direction of the change here */
    color: #000;
    text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/scrollButton/scrollButton.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,QAAQ;IACR,SAAS;IACT,YAAY;IACZ,eAAe;IACf,UAAU;IACV,eAAe;IACf,qBAAqB;IACrB,0DAA0D;IAC1D,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;IAChB,0BAA0B;IAC1B,WAAW;IACX,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,iCAAiC,EAAE,4CAA4C;IAC/E,WAAW;IACX,qBAAqB;AACzB","sourcesContent":[".scrollButton {\n    position: fixed;\n    width: 100px;\n    right: 0;\n    bottom: 0;\n    height: 20px;\n    font-size: 1rem;\n    z-index: 1;\n    cursor: pointer;\n    text-decoration: none;\n    background-image: linear-gradient(90deg, #59b720, #eeff00);\n    margin: 10px;\n    padding: 15px;\n    text-align: center;\n    text-transform: uppercase;\n    transition: 0.5s;\n    background-size: 200% auto;\n    color: #fff;\n    border-radius: 10px;\n    font-weight: 500;\n}\n\n.scrollButton:hover {\n    background-position: right center; /* change the direction of the change here */\n    color: #000;\n    text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
