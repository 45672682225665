// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner {
    height: 100vh;
}

.bannerWrapper {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bannerTitle {
    font-family: Inter, sans-serif;
    background-color: #1a1919;
    background-image: linear-gradient(90deg, #59b720, #eeff00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 10vmax;
    text-transform: uppercase;
}

@media (max-width: 500px) {
    .bannerTitle {
        font-size: 6vmax;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/banner/banner.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,8BAA8B;IAC9B,yBAAyB;IACzB,0DAA0D;IAC1D,6BAA6B;IAC7B,oCAAoC;IACpC,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".banner {\n    height: 100vh;\n}\n\n.bannerWrapper {\n    position: absolute;\n    inset: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.bannerTitle {\n    font-family: Inter, sans-serif;\n    background-color: #1a1919;\n    background-image: linear-gradient(90deg, #59b720, #eeff00);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n    font-size: 10vmax;\n    text-transform: uppercase;\n}\n\n@media (max-width: 500px) {\n    .bannerTitle {\n        font-size: 6vmax;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
