// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loaderWrapper {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.loader {
    width: 150px;
    height: 150px;
    border: 5px solid #eeff00;
    border-top-color: #59b720;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/loader/loader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,kCAAkC;AACtC;;AAEA;IACI;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".loaderWrapper {\n    display: flex;\n    height: 100vh;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n}\n\n.loader {\n    width: 150px;\n    height: 150px;\n    border: 5px solid #eeff00;\n    border-top-color: #59b720;\n    border-radius: 50%;\n    animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n    to {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
