export default [
    {
        id: "1",
        link: "wholesale-order",
        title: " \"Оптовый заказ\" для Supplement Group",
        description: "Веб-приложение для формирования оптовых закупок.",
        cardImage: "/images/vt-catalog/vt-catalog.png",
        type: "web",
        client: "Supplement Group, ведущий поставщик БАДов (биологически активных добавок)",
        problem: "Создать веб-приложение, которое позволит клиентам эффективно и удобно оформлять оптовые заказы БАДов.",
        solution: "Мы разработали веб-приложение с интуитивно понятным интерфейсом, предоставляющее пользователям возможность легко просматривать продукцию, управлять заказами и отслеживать доставку. Одной из ключевых особенностей стала интеграция с системами управления запасами и логистики, что позволило автоматизировать и оптимизировать процесс заказа и доставки.",
        technologiesDescription: "Для фронтенда использовались HTML5, CSS3 и TypeScript с фреймворком React, обеспечивая динамичный и отзывчивый пользовательский интерфейс. Бэкэнд был реализован на PHP, что позволило создать надежную и масштабируемую серверную часть с высокой производительностью и гибкостью.",
        technologies: [ "PHP", "Symfony", "Docker", "TypeScript", "React", "Redux Toolkit"],
        result: "Веб-приложение \"Оптовый заказ\" существенно упростило процедуру оптового заказа для клиентов Supplement Group, улучшило их взаимодействие с компанией и повысило их удовлетворенность. Это также способствовало улучшению управления заказами и оптимизации запасов со стороны компании.",
        images: {
            1: "/images/vt-catalog/vt-catalog-imac.png",
            2: "/images/vt-catalog/vt-catalog-macbook.png",
            3: "/images/vt-catalog/vt-catalog-flying-macbook.png",
            4: "/images/vt-imac.png",
        }
    },
    {
        id: "2",
        link: "wholesale-order-stm-clients",
        title: "\"Оптовый заказ для СТМ клиентов\" для Supplement Group",
        description: "Веб приложение для формирования оптовых закупок для СТМ покупателей.",
        cardImage: "/images/vt-stm/vt-stm.png",
        type: "web",
        client: "Supplement Group, ведущий поставщик БАДов (биологически активных добавок).",
        problem: "Разработать продвинутое веб-приложение, позволяющее клиентам СТМ (собственных торговых марок) оформлять оптовые заказы БАДов с возможностью настройки упаковки, состава и других параметров.",
        solution: "Мы создали \"Оптовый заказ для СТМ клиентов\" - платформу, которая расширяет функциональность нашего предыдущего проекта для Supplement Group. Это веб-приложение позволяет пользователям не только оформлять оптовые заказы, но и настраивать продукты под свои бренды, выбирая упаковку, состав и другие ключевые параметры. Разработанный интуитивный интерфейс упрощает процесс настройки и заказа, позволяя клиентам легко персонализировать продукты в соответствии с их брендовыми стандартами и требованиями.",
        technologiesDescription: "Для фронтенда мы использовали HTML5, CSS3, TypeScript с React, создавая гладкий и интерактивный пользовательский опыт. Бэкэнд был разработан на PHP, обеспечивая надежность и масштабируемость. Также была интегрирована система управления заказами и запасами для автоматизации и упрощения процессов.",
        technologies: [ "PHP", "Symfony", "Docker", "TypeScript", "React", "Redux Toolkit"],
        result: "Наше веб-приложение \"Оптовый заказ для СТМ клиентов\" значительно улучшило возможности клиентов Supplement Group по индивидуализации и заказу продуктов. Это не только повысило удовлетворенность клиентов, но и обеспечило более гибкое и эффективное управление заказами для компании.",
        images: {
            1: "/images/vt-stm/vt-stm-imac.png",
            2: "/images/vt-stm/vt-stm-floating-macbook.png",
            3: "/images/vt-stm/vt-stm-ipad.png",
            4: "/images/vt-stm/vt-stm-iphone.png",
        }
    },
    {
        id: "3",
        link: "recipe-management-system",
        title: "\"Система управления рецептами\" для Supplement Group",
        description: "Внутреннее веб-приложение управления рецептурой.",
        cardImage: "/images/vt-recipes/vt-recipes.png",
        type: "web",
        client: "Supplement Group, ведущий производитель и поставщик биологически активных добавок.",
        problem: "Разработать специализированное веб-приложение для внутреннего использования компанией, предназначенное для управления рецептурами добавок.",
        solution: "Мы создали \"Система управления рецептами\", интуитивно понятное веб-приложение, которое позволяет специалистам Supplement Group эффективно управлять и оптимизировать рецептуры БАДов. Эта система обеспечивает централизованное управление данными о составе продуктов, их дозировках и свойствах. Особое внимание было уделено безопасности данных и возможности их аудита, что критически важно для сферы производства добавок",
        technologiesDescription: "Технологии: Для создания приложения использовались современные веб-технологии, включая HTML5, CSS3 и TypeScript, с применением React для обеспечения динамического и отзывчивого пользовательского интерфейса. Бэкэнд был реализован с использованием PHP, что обеспечило надежность, безопасность и масштабируемость системы.",
        result: "\"Система управления рецептами\" значительно упростил и ускорил процессы управления рецептурами в Supplement Group, обеспечивая быстрый доступ к данным и удобство их редактирования. Система также способствовала повышению точности и сокращению времени на разработку новых продуктов.",
        technologies: [ "PHP", "Symfony", "Docker", "TypeScript", "React", "Redux Toolkit"],
        images: {
            1: "/images/vt-recipes/vt-recipes-imac.png",
            2: "/images/vt-recipes/vt-recipes-right-macbook.png",
            3: "/images/vt-recipes/vt-recipes-flying-macbook.png",
            4: "/images/vt-imac.png",
        }
    },
    {
        id: "4",
        link: "news-management",
        title: "\"Управление новинками\" для Supplement Group",
        description: "Внутреннее веб-приложение для управления выводом новинок на рынок.",
        cardImage: "/images/vt-news/vt-news.png",
        type: "web",
        client: "Supplement Group, известный лидер в области производства и поставок биологически активных добавок.",
        problem: "Создать комплексное веб-приложение для внутреннего использования, предназначенное для управления процессом разработки и вывода новинок на рынок.",
        solution: "Наша команда разработала \"Управление новинками\" - передовую платформу, которая централизует и упрощает процессы разработки, тестирования, одобрения и запуска новых продуктов в линейку Supplement Group. Веб-приложение включает модули для управления проектами, мониторинга стадий разработки, согласования с нормативными требованиями и анализа рыночных тенденций. Особое внимание было уделено функциональности совместной работы и обмена данными между различными отделами компании.",
        technologiesDescription: "Используя современные веб-технологии, такие как HTML5, CSS3, TypeScript и React для фронтенда, мы обеспечили динамичный и интуитивно понятный интерфейс. Бэкэнд был построен на PHP, гарантируя надежность, безопасность и масштабируемость системы.",
        technologies: [ "PHP", "Symfony", "Docker", "TypeScript", "React", "Redux Toolkit"],
        result: "\"Управление новинками\" позволил Supplement Group значительно ускорить процесс разработки и вывода новых продуктов на рынок, улучшить взаимодействие между отделами и повысить эффективность принятия решений на основе данных. Платформа также способствовала более эффективному управлению ресурсами и сокращению времени на рыночный запуск новых продуктов.",
        images: {
            1: "/images/vt-news/vt-news-imac.png",
            2: "/images/vt-news/vt-news-right-macbook.png",
            3: "/images/vt-news/vt-news-flying-macbook.png",
            4: "/images/vt-imac.png",
        }
    },
    {
        id: "5",
        link: "vt-operator",
        title: " \"VT.Оператор\" для Vitamin Trade",
        description: "Мобильное приложение Vitamin.Trade.Оператор - для управления поставками и управления складом.",
        cardImage: "/images/vt-operator/vt-operator.png",
        type: "mobile",
        client: "Vitamin Trade, специализированное подразделение компании Supplement Group, занимающееся торговлей витаминами и добавками.",
        problem: "Разработать мобильное приложение для операторов складов и комплектовщиков, упрощающее процесс отправки товаров путем автоматизации печати номенкулатурных стикеров для наклейки на коробки перед их отправкой.",
        solution: "Наша команда разработала \"VT.Оператор\" – мобильное приложение, которое трансформирует процессы работы склада, делая их более эффективными и менее подверженными ошибкам. Приложение позволяет операторам сканировать товары, автоматически создавать и отправлять на печать соответствующие номенкулатурные стикеры, что значительно ускоряет процесс подготовки заказов к отправке.",
        technologiesDescription: "Приложение разработано с использованием нативных технологий для Android, обеспечивая высокую производительность и удобство использования на различных устройствах. Особое внимание было уделено интеграции с существующими системами управления складом и базами данных для обеспечения бесперебойной работы и синхронизации данных.",
        technologies: [ "1C", "TypeScript", "React Native", "Redux Toolkit", "RTK Query"],
        result: " \"VT.Оператор\" значительно упростил и оптимизировал процессы на складах Vitamin Trade, сократив время на подготовку и отправку заказов. Процесс печати стикеров теперь автоматизирован, что снижает вероятность ошибок и повышает эффективность работы операторов.",
        images: {
            1: "/images/vt-operator/vt-operator-realme.png",
            2: "/images/vt-operator/vt-operator-scan.png",
            3: "/images/vt-operator/vt-operator-isometric.png",
        }
    },
    {
        id: "6",
        link: "vt-executor",
        title: "\"VT.Executor\" для Vitamin Trade",
        description: "Мобильное приложение, которое революционизирует процессы на производственных линиях и складах Vitamin Trade.",
        cardImage: "/images/vt-executor/vt-executor.png",
        type: "mobile",
        client: "Vitamin Trade, ключевое подразделение компании Supplement Group, специализирующееся на производстве и торговле витаминами и добавками",
        problem: "Разработать мобильное приложение для операторов складов и комплектовщиков, направленное на автоматизацию процессов производства и фасовки продуктов.",
        solution: "Мы создали \"VT.Executor\", мобильное приложение, которое революционизирует процессы на производственных линиях и складах Vitamin Trade. Приложение предоставляет операторам инструменты для управления и мониторинга всех этапов производства и упаковки, от сырья до готового продукта. Функциональность включает в себя сканирование штрих-кодов, отслеживание партий сырья и готовой продукции, а также автоматизацию процессов фасовки и упаковки.",
        technologiesDescription: "Приложение разработано с использованием передовых мобильных технологий для iOS и Android, обеспечивая надежную и эффективную работу в производственных условиях. Взаимодействие с базами данных и интеграция с системами ERP и CRM Vitamin Trade позволили обеспечить высокую степень автоматизации и синхронизации данных.",
        technologies: [ "PHP", "Symfony", "Docker", "TypeScript", "React Native", "Redux Toolkit", "RTK Query"],
        result: "\"VT.Executor\" существенно улучшил процессы производства и упаковки в Vitamin Trade, увеличивая производительность и сокращая время на подготовку продукции к отгрузке. Автоматизация ключевых процессов также помогла минимизировать вероятность ошибок и улучшить контроль качества.",
        images: {
            1: "/images/vt-executor/vt-executor-two-phone.png",
            2: "/images/vt-executor/vt-executor-scan.png",
            3: "/images/vt-executor/vt-executor-realme.png",
            4: "/images/vt-executor/vt-executor-three-phone.png",
        }
    },
    {
        id: "7",
        link: "qeep-pro",
        title: "QEEP-Pro",
        description: "Наша собственная разработка CRM-системы, предназначенная для управления заказами в кафе и ресторанах.",
        cardImage: "./images/qeep-pro/qeep-pro.png",
        type: "web",
        concept: "Наша собственная разработка CRM-системы, предназначенная для управления заказами в кафе и ресторанах. Этот проект является результатом тщательных исследований и разработок в области обслуживания клиентов.",
        fullDescription: "Наша CRM-система представляет собой комплексное решение, специально разработанное для потребностей кафе и ресторанов. Она обеспечивает эффективное управление заказами, от момента их получения до выполнения, включая управление запасами, анализ предпочтений клиентов и автоматизацию маркетинговых кампаний. Интуитивно понятный интерфейс позволяет персоналу легко взаимодействовать с системой, повышая эффективность работы и удовлетворенность клиентов.",
        improvement: "Мы придерживаемся философии непрерывного улучшения и ежедневно работаем над доработкой нашей CRM-системы. Это включает в себя добавление новых функций, оптимизацию производительности и улучшение пользовательского опыта на основе обратной связи от наших клиентов и изменений в отраслевых трендах.",
        technologies: [ "PHP", "Symfony", "Docker", "TypeScript", "React", "Redux"],
        technologiesDescription: "Система разработана с использованием передовых технологий и облачных решений, что гарантирует её надежность, масштабируемость и доступность с любого устройства. Также интегрированы аналитические инструменты для глубокого понимания потребностей клиентов и оптимизации бизнес-процессов.",
        result: "Наша CRM-система уже помогла многим ресторанам и кафе повысить эффективность управления заказами, улучшить качество обслуживания и увеличить уровень удовлетворенности клиентов. Она стала незаменимым инструментом в повседневной работе наших клиентов, помогая им достигать высоких стандартов обслуживания и операционной эффективности.",
        images: {
            1: "/images/qeep-pro/qeep-pro-imac.png",
            2: "/images/qeep-pro/qeep-pro-macbook.png",
            3: "/images/qeep-pro/qeep-pro-flying-macbook.png",
            4: "/images/qeep-pro/qeep-pro-ipad.png",
        }
    },
    {
        id: "8",
        title: "QEEP-Shop",
        link: "qeep-shop",
        description: "QEEP-Shop — это инновационный генератор сайтов для электронной коммерции, интегрированный с нашей флагманской CRM системой QEEP-pro.",
        cardImage: "/images/qeep-shop/qeep-shop.png",
        type: "web",
        concept: "QEEP-Shop — это инновационный генератор сайтов для электронной коммерции, интегрированный с нашей флагманской CRM системой QEEP-pro. Это решение предназначено для создания настраиваемых веб-сайтов для онлайн-торговли, которые напрямую связаны с CRM-системой, обеспечивая беспрепятственную интеграцию заказов и клиентских данных.",
        fullDescription: "QEEP-Shop представляет собой платформу, которая позволяет пользователям быстро и легко создавать персонализированные веб-сайты для электронной коммерции. Система предлагает широкий спектр инструментов для управления контентом. Все заказы, сделанные через сайты QEEP-Shop, автоматически синхронизируются с CRM-системой QEEP-pro, что обеспечивает эффективное управление клиентскими отношениями и заказами.",
        improvement: "Проект QEEP-Shop находится в постоянном развитии, с ежедневными обновлениями и улучшениями. Мы активно работаем над расширением функциональности, повышением производительности и улучшением пользовательского интерфейса, чтобы обеспечить нашим клиентам лучший опыт использования.",
        technologies: [ "PHP", "Symfony", "Docker", "JavaScript ", "Vue", "Vuex"],
        technologiesDescription: "Платформа разработана на основе современных веб-технологий, обеспечивая гибкость, масштабируемость и высокую производительность. Интеграция с QEEP-pro позволяет автоматизировать многие процессы, связанные с управлением заказами и клиентскими данными.",
        result: "С помощью QEEP-Shop предприятия быстро и эффективно запустили свои онлайн-магазины, обеспечив удобное управление заказами и клиентскими данными. Платформа помогает бизнесам расширять свое онлайн-присутствие, увеличивать продажи и улучшать взаимодействие с клиентами.",
        images: {
            1: "/images/qeep-shop/qeep-shop-imac.png",
            2: "/images/qeep-shop/qeep-shop-iphone.png",
            3: "/images/qeep-shop/qeep-shop-ipad.png",
            4: "/images/qeep-shop/qeep-shop-macbook.png",
        }
    },
    {
        id: 9,
        title: "Интерактивная Карта Информационно-Связной Системы Арктического Транспортного Комплекса",
        link: "interactive-map",
        cardImage: "/images/im/interactive-map.png",
        type: "web",
        client: "Министерство транспорта Российской Федерации.",
        objective: "Разработка научно-технического обеспечения для создания единой защищенной информационно-связной системы, предназначенной для управления транспортным комплексом Арктической зоны Российской Федерации.",
        description: "Карта демонстрирует ключевые элементы системы, включая маршруты транспортных потоков, точки связи, центры управления и зоны обеспечения безопасности.",
        fullDescription: "Этот проект представляет собой уникальное сочетание научных исследований и технических новшеств. Интерактивная карта иллюстрирует всесторонние результаты работы, включая планы построения и развития инфраструктуры, а также параметры обеспечения безопасности и связи в сложных условиях Арктики. Карта демонстрирует ключевые элементы системы, включая маршруты транспортных потоков, точки связи, центры управления и зоны обеспечения безопасности.",
        projectFeatures: "Проект включает в себя анализ и интеграцию большого объема данных о транспортной инфраструктуре, условиях эксплуатации в Арктической зоне и требованиях безопасности. Интерактивная карта обеспечивает наглядное представление сложной информации, что способствует более эффективному планированию, управлению и мониторингу транспортных процессов в этом стратегически важном регионе",
        projectSignificance: "Разработанный проект является ключевым элементом в стратегии развития транспортной инфраструктуры Арктической зоны Российской Федерации. Он не только улучшает понимание текущего состояния транспортной сети, но и вносит значительный вклад в планирование будущего развития, повышая безопасность и эффективность транспортных операций в этом регионе.",
        technologiesDescription: "В основе проекта лежат передовые технологии обработки данных и визуализации, что позволяет пользователям в реальном времени взаимодействовать с данными и получать актуальную информацию о состоянии транспортной системы",
        technologies: [ "PHP", "Symfony", "Docker", "TypeScript", "React", "Redux"],
        resultWork: "https://arctic.qeep.pro",
        images: {
            1: "/images/im/im-imac.png",
            2: "/images/im/im-ipads.png",
            3: "/images/im/im-macbook.png",
            4: "/images/im/interactive-map.png",
        }
    },
    {
        id: 10,
        title: "Интегрированная Система Мониторинга и Управления",
        link: "ismu",
        cardImage: "/images/ismu/ismu.png",
        description: "Система предназначена для сбора, обработки и анализа данных с измерительных устройств, используемых на промышленных объектах Газпрома.",
        type: "web",
        client: "Газпром, ведущий мировой энергетический гигант.",
        objective: "Разработка и внедрение комплексной системы для мониторинга и управления процессами, связанными с добычей и переработкой природного газа.",
        fullDescription: "Мы разработали передовой программный комплекс, состоящий из центрального блока управления, веб-приложения и мобильного приложения. Система предназначена для сбора, обработки и анализа данных с измерительных устройств, используемых на промышленных объектах Газпрома. Информация собирается в реальном времени и отображается как на встроенном дисплее центрального блока, так и через веб- и мобильные интерфейсы для удобного анализа и управления.",
        featuresAndInnovations: [
            "Веб-приложение и мобильное приложение обеспечивают оперативный доступ к данным и удобство их визуализации.",
            "Использование Node.js и JavaScript для создания надежных и эффективных систем реального времени.",
            "Применение нереляционной базы данных Redis для быстрого доступа и обработки больших объемов данных.",
            "Интегрированные функции автоматического опроса измерительных устройств и системы очистки.",
            "Разработка API и веб-приложения на основе Angular.js для эффективного вывода данных.",
            "Мобильное приложение на платформе Cordova, оптимизированное для использования в полевых условиях.",
        ],
        businessResult: "Наша система обеспечивает Газпром мощным инструментом для мониторинга и управления процессами на промышленных объектах. Это позволяет компании повышать эффективность работы, сокращать время реакции на изменения в процессах и улучшать безопасность. Система способствует повышению общей производительности и оптимизации операций, что является ключевым фактором для устойчивого развития и лидерства Газпрома на мировом энергетическом рынке.",
        images: {
            1: "/images/ismu/ismu-imac.png",
            2: "/images/ismu/ismu-table-imac.png",
            3: "/images/ismu/ismu-macbook.png",
        }
    },
]